<!-- Eligibility Screen (as called by product owners) -->
<div class="flex flex-col items-center px-48">
    <h1 class="md:text-center text-xl text-gray-darker text-center mt-20">What is the address you would like to add a
        Home Protection Plan to?</h1>
    <br><br>
    <app-address-search (setAddress)="answerForAddress($event)"></app-address-search>

    <div>
        <h1 class="md:text-center text-xl text-gray-darker text-center mt-20">Next lets get some details about your
            residence</h1>

        <form *ngIf="eligForm" [formGroup]="eligForm">

            <!-- TODO: Angular NG0100 Error ExpressionChangedAfterItHasBeenCheckedError - with the radio button group.  Works fine but should investigate eds library at some point -->
            <eds-radio-group (inputChange)="answerForResidentialQuestion($event)" [control]="residentialControl"
                [label]="'Is the property residential or non-residential?'" [description]="''" [size]="'md'"
                class="p-48 mt-12">
                <eds-radio [label]="'Residential'"></eds-radio>
                <eds-radio [label]="'Non-Residential (Business)'"></eds-radio>
            </eds-radio-group>

            <eds-radio-group (inputChange)="answerForTypeOfPropertyQuestion($event)"
                [label]="'What type of property is this?'" [description]="''" [size]="'md'" class="p-48 mt-12">
                <eds-radio [label]="'Single Family'"></eds-radio>
                <eds-radio [label]="'Multi-family'"></eds-radio>
                <eds-radio [label]="'Mobile Home'"></eds-radio>
            </eds-radio-group>

            <eds-radio-group (inputChange)="answerForOwnOrRentQuestion($event)" [label]="'Do you own or rent?'"
                [description]="''" [size]="'md'" class="p-48 mt-12">
                <eds-radio [label]="'Own'"></eds-radio>
                <eds-radio [label]="'Rent'"></eds-radio>
            </eds-radio-group>

        </form>

        <!-- <eds-button (buttonClick)="submitForm()" text="Submit" type="secondary" extraTwClasses="p-32"></eds-button> -->

        <eds-button-group [primaryText]="'Continue'" [secondaryText]="'Previous'"
            (primaryClick)="continueActionClicked()" (secondaryClick)="previousActionClicked()">
        </eds-button-group>

    </div>
</div>