import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/app.config';
import { Observable, map } from 'rxjs';
import { Customer } from '../shared/models/customer';
import { TermsRequest, TermsResponse } from '../Models/terms.models';
import { EnrollmentRequest, EnrollmentResponse } from '../Models/enrollment.models';
import { LocalAWSCartRequest, LocalAWSCartResponse } from '../Models/cart.models';
import { SignInResponse } from '../Models/SignInResponse';

const APPLICATION_JSON = 'application/json';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _apiBaseUrl = '';

  constructor(
    private _httpClient: HttpClient,
    private _config: AppConfig) {
      this._apiBaseUrl = this._config.getConfig('apiBaseUrl');
   }

   public getNewToken(): Observable<any> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.get<any>(`${this._apiBaseUrl}auth/token`,{headers})
    .pipe(map((response:any) => {
      const token = response?.token;
      this._config.setConfig('accessToken', token);
      return response;
    }))
  }

   public signInCustomer(username: string, password: string): Observable<SignInResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = {
      u: username,
      p: password
    };

    return this._httpClient.post<any>(`${this._apiBaseUrl}auth/sign-in`, body, {headers})
    .pipe(map((response) => {
      return response as SignInResponse
    }));
   }

   public recordTransferProfileResponse(transferResponse: string): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = {
      transferResponse: transferResponse
    };

    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/transfer-profile`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public createProfile(customer: Customer): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = {
      email: customer?.email,
      password: customer?.password,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      phoneNumber: customer?.phone.number,
      phoneType: customer?.phone.type
    };
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/create-profile`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }


   public isRegistered(email: string): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = { email: email };
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/verify-registered-email`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }
   public sendValidationEmail(email: string): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = { email: email };
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/send-validation-email`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public updateTermsAndConditions(request: TermsRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/terms`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public getTermsAndConditions(): Observable<TermsResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const options = { headers: headers };
    return this._httpClient.get<any>(`${this._apiBaseUrl}customer/terms`, options)
    .pipe(map((response) => {
      return response as TermsResponse
    }));
   }

   public updateEnrollment(request: EnrollmentRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/enrollment`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public getEnrollment(): Observable<EnrollmentResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const options = { headers: headers };
    return this._httpClient.get<any>(`${this._apiBaseUrl}customer/enrollment`, options)
    .pipe(map((response) => {
      return response as EnrollmentResponse
    }));
   }


   public updateAWSLocalCartWithCustomer(customer: Customer): Observable<string> {

    const request:LocalAWSCartRequest=    { 
      'openedInSAP': false,
      'zip': Number(customer.address?.zipCode),
      'isResidential':customer.isResidential,
      'address':customer.address?.street,
      'businessPartnerId':customer.businessPartnerID,
      'email':customer.email,
      'state':customer.address?.state,
      'address2':customer.address?.street,
      'city':customer.address?.city,
      'sapCartId': null,
      'products':customer.addedPlans,
      'propertyType':customer.typeOfProperty,
       'ownRent':customer.isOwner ? 'own' : 'rent'
      };


     return this.updateAWSLocalCartWithRequest(request);
   }

   public updateAWSLocalCartWithRequest(request: LocalAWSCartRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/data`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public getLocalAWSCart(email: string): Observable<LocalAWSCartResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const params = new HttpParams().set('email', email);
    const options = { params: params, headers: headers };
    return this._httpClient.get<any>(`${this._apiBaseUrl}customer/data`, options)
    .pipe(map((response) => {
      return response as LocalAWSCartResponse
    }));
   }
}
